<template>
  <section>
    <div class="content-header">
      <h2>Overview: Teaching and learning strategies to promote belonging and wellbeing</h2>
    </div>
    <div class="content-wrapper">
      <p>The University of British Columbia has created a range of exceptional resources designed to build safe and inclusive learning environments that promote student belonging and wellbeing.</p>
      <p>Explore the learning resources, and practical tools you can apply within your classroom on their <a href="https://wellbeing.ubc.ca/tools-and-resources/teaching-learning-resources" target="_blank">website</a>. </p>
      <p>Specific resources that will support community resilience include:</p>
      <ul>
        <li class="pb-3">A module on <a href="https://wellbeing.ubc.ca/building-safe-and-supportive-learning-environments" target="_blank">Building Safe and Supportive Learning Environments</a></li>
        <li><a href="https://wellbeing.ubc.ca/teaching-practices-support-wellbeing" target="_blank">Teaching Practices that Support Wellbeing</a></li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
